
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import AppHeading from '@/components/HeadingComponent.vue';
import PatientTable from '@/components/PatientTable.vue';
import PatientCreateUpdateModal from '@/components/PatientCreateUpdateModal.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import EmptyState from '@/components/EmptyState.vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import Patient, { PatientDto } from '@/models/Patient';
import GetRequestError from '@/components/common/GetRequestError.vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { chatService } from '@/services/ChatService';
import { ChatFormDto } from '@/models/Chat';
export default defineComponent({
  components: { AppHeading, PatientTable, PatientCreateUpdateModal, LoadingComponent, EmptyState, GetRequestError },
  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const route = useRoute();

    const filter = Array.isArray(route.query.filter) ? route.query.filter[0] : route.query.filter;

    const patientCreateModalRef = ref();
    const patients: Ref<PatientDto[] | undefined> = ref();
    const isLoadingPatients = ref(true);
    const isPatientsListEmpty = computed(() => patients.value !== undefined && patients.value.length === 0);
    const isPatientsRequestErrorful = ref(false);
    const isEditModal = ref(false);
    const patientModalRef = ref();
    const loadPatientList = async () => {
      isLoadingPatients.value = true;
      const result = await patientService.findAll();
      if (!(result instanceof ServiceError)) {
        patients.value = result;
      } else if (result.status === 500) {
        isPatientsRequestErrorful.value = true;
      }
      isLoadingPatients.value = false;
    };

    onMounted(async () => {
      await loadPatientList();
    });

    const onAddPatientDialog = () => {
      isEditModal.value = false;
      const newPatient = {} as Patient;
      patientModalRef.value.openDialog(newPatient);
    };

    const onEditPatient = async (patientId: string) => {
      router.push(`/patient/${patientId}/configuration/patientData`);
    };

    const onViewPatient = async (id: string) => {
      await router.push(`/patient/${id}/patient-data/medical-history`);
    };

    const onCreateChat = async (userId: string) => {
      const dto = new ChatFormDto(userId);
      const result = await chatService.createChat(dto);
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorCreatingChat')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        router.push('/chats?chatId=' + result.id);
      }
    };

    return {
      route,
      router,
      filter,
      patientCreateModalRef,
      patients,
      isLoadingPatients,
      isPatientsListEmpty,
      isPatientsRequestErrorful,
      onViewPatient,
      onAddPatientDialog,
      loadPatientList,
      isEditModal,
      onEditPatient,
      onCreateChat,
      patientModalRef,
    };
  },
});
