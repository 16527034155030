
import { defineComponent, computed, ref, PropType } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { Patient, PatientDto } from '@/models/Patient';
import dateFormat from '@/helpers/DateFormat.helper';
import { MedicalTestTypeValue } from '@/models/MedicalTest';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import Tag from 'primevue/tag';

export default defineComponent({
  components: { Tag },
  emits: ['patient-detail-click', 'edit-patient-click', 'chat-create-click'],
  props: {
    patients: {
      type: Array as PropType<PatientDto[]>,
    },
    initFilterBy: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = computed(() => props.patients == undefined);
    const showPaginator = computed(() => props.patients && props.patients.length > 10);
    const filterBy = ref(props.initFilterBy ?? 'all');

    const patientsFiltered = computed(() => {
      if (filterBy.value === 'active') {
        return props.patients?.filter((p) => p.inactive !== true);
      } else if (filterBy.value === 'medicalTestInProgress') {
        return props.patients?.filter((p) => p.medicalTestInProgress !== undefined && p.medicalTestInProgress !== null);
      } else if (filterBy.value === 'wereables') {
        return props.patients?.filter((p) => p.wearables === true);
      } else if (filterBy.value === 'nutritionalPlan') {
        return props.patients?.filter((p) => p.nutritionalPlan === true);
      }

      return props.patients;
    });

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nextDate: { value: null, matchMode: 'filterEqualDate' },
      inactive: { value: null, matchMode: FilterMatchMode.NOT_EQUALS },
      coupon: { value: null, matchMode: FilterMatchMode.IN },
    });

    const initFilters = () => {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nextDate: { value: null, matchMode: 'filterEqualDate' },
        inactive: { value: null, matchMode: FilterMatchMode.NOT_EQUALS },
        coupon: { value: null, matchMode: FilterMatchMode.IN },
      };

      filterBy.value = 'all';
    };

    const rowClickEvent = (event: any) => {
      onPatientDetail(event.data as Patient);
    };

    const onPatientDetail = (patient: Patient) => {
      emit('patient-detail-click', patient.id);
    };

    const onPatientEdit = (patient: Patient) => {
      emit('edit-patient-click', patient.id);
    };

    const onCreateChat = (patient: Patient) => {
      emit('chat-create-click', patient.userId);
    };

    const getOwnerSpecialists = (patient: PatientDto) => {
      return patient.specialists.filter((s) => s.permission === 'OWNER');
    };

    const getViewerSpecialists = (patient: PatientDto) => {
      return patient.specialists.filter((s) => s.permission === 'VIEWER');
    };

    const getTagFromTestType = (type: MedicalTestTypeValue) => {
      if (type === MedicalTestTypeValue.CALORIMETRY) {
        return 'p-tag--calorimetria';
      } else {
        return 'p-tag--cgm';
      }
    };

    const getLastAgo = (lastActivity: string) => {
      const currentDate = moment();
      const lastActivityDate = dateFormat.utcToUserTimezone(lastActivity);

      const duration = moment.duration(currentDate.diff(lastActivityDate));

      if (duration.asMinutes() < 60) {
        return `${t('patient.last')} ${duration.asMinutes().toFixed(0)} ${t('patient.minutesAgo')} `;
      } else if (duration.asHours() < 24) {
        return `${t('patient.last')} ${duration.asHours().toFixed(0)} ${t('patient.hoursAgo')} `;
      } else {
        return `${t('patient.last')} ${duration.asDays().toFixed(0)} ${t('patient.daysAgo')} `;
      }
    };

    const changeFilter = (newFilter: string) => {
      filterBy.value = newFilter;
    };

    const couponOptions = computed(() => {
      return (
        [...new Set(props.patients?.filter((patient) => patient.coupon).map((patient) => patient.coupon))]
          .map((coupon) => ({
            label: coupon,
            value: coupon,
          }))
          .sort((a, b) => (a.label || '').localeCompare(b.label || '')) || []
      );
    });

    return {
      patientsFiltered,
      filterBy,
      filters,
      loading,
      showPaginator,
      dateFormat,
      initFilters,
      onPatientDetail,
      rowClickEvent,
      onPatientEdit,
      onCreateChat,
      getOwnerSpecialists,
      getViewerSpecialists,
      getTagFromTestType,
      getLastAgo,
      changeFilter,
      couponOptions,
    };
  },
});
