import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between" }
const _hoisted_2 = { class: "p-input-icon-left p-col-4" }
const _hoisted_3 = { class: "table-filters-panel" }
const _hoisted_4 = { class: "p-column-title" }
const _hoisted_5 = {
  key: 0,
  src: "/assets/icon/checkbox/active-checkbox-disabled.svg",
  alt: "true"
}
const _hoisted_6 = {
  key: 1,
  src: "/assets/icon/checkbox/inactive-checkbox-disabled.svg",
  alt: "true"
}
const _hoisted_7 = {
  key: 0,
  src: "/assets/icon/checkbox/active-checkbox-disabled.svg",
  alt: "true"
}
const _hoisted_8 = {
  key: 1,
  src: "/assets/icon/checkbox/inactive-checkbox-disabled.svg",
  alt: "true"
}
const _hoisted_9 = { class: "specialist-item-wraper" }
const _hoisted_10 = { class: "specialist-item-wraper" }
const _hoisted_11 = {
  key: 0,
  class: "inactive-patient-label"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "inactive-filters" }
const _hoisted_14 = {
  for: "verified-filter",
  class: "font-bold"
}
const _hoisted_15 = {
  for: "verified-filter-2",
  class: "font-bold"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = ["aria-label"]
const _hoisted_19 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.patientsFiltered)
    ? (_openBlock(), _createBlock(_component_DataTable, {
        key: 0,
        ref: "dt",
        filters: _ctx.filters,
        "onUpdate:filters": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filters) = $event)),
        value: _ctx.patientsFiltered,
        "data-key": "id",
        rows: 10,
        "filter-display": "menu",
        loading: _ctx.loading,
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
        paginator: _ctx.showPaginator,
        "responsive-layout": "scroll",
        "global-filter-fields": ['name', 'surname', 'gender', 'email', 'phoneNumber', 'coupon'],
        "sort-field": "name",
        "sort-order": 1,
        "removable-sort": "",
        rowHover: true,
        onRowClick: _ctx.rowClickEvent
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, [
              _cache[8] || (_cache[8] = _createElementVNode("i", {
                class: "icon-search",
                "aria-hidden": "true"
              }, null, -1)),
              _createVNode(_component_InputText, {
                modelValue: _ctx.filters['global'].value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters['global'].value) = $event)),
                placeholder: _ctx.$t('table.search.patients')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _createVNode(_component_Button, {
              type: "button",
              class: "p-button p-component p-button-secondary p-button-medium p-button-icon",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.initFilters()))
            }, {
              default: _withCtx(() => [
                _cache[9] || (_cache[9] = _createElementVNode("i", {
                  class: "icon-trash_empty",
                  "aria-hidden": "true"
                }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('table.clearFilters')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["filter-button", { active: _ctx.filterBy === 'all' }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeFilter('all')))
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('patients.all')), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["filter-button", { active: _ctx.filterBy === 'active' }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeFilter('active')))
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('patients.active')), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["filter-button", { active: _ctx.filterBy === 'medicalTestInProgress' }]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeFilter('medicalTestInProgress')))
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('patients.medicalTestInProgress')), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["filter-button", { active: _ctx.filterBy === 'wereables' }]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeFilter('wereables')))
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('patients.wereables')), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["filter-button", { active: _ctx.filterBy === 'nutritionalPlan' }]),
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeFilter('nutritionalPlan')))
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('patients.nutritionalPlan')), 1)
            ], 2)
          ])
        ]),
        empty: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('patients.notFound')), 1)
        ]),
        loading: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('patients.loading')) + "s ", 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: _ctx.$t('patient.patient'),
            sortable: ""
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.name) + " " + _toDisplayString(slotProps.data.surname), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "coupon",
            "filter-field": "coupon",
            showClearButton: false,
            showApplyButton: false,
            showFilterMatchModes: false
          }, {
            header: _withCtx(() => [
              _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createTextVNode(_toDisplayString(_ctx.$t('patient.coupon')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.$t('patient.couponInfo'),
                  void 0,
                  { bottom: true }
                ]
              ])
            ]),
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.coupon ? data.coupon : '-'), 1)
            ]),
            filter: _withCtx(({ filterModel, filterCallback }) => [
              _createVNode(_component_MultiSelect, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                options: _ctx.couponOptions,
                "option-label": "label",
                "option-value": "value",
                placeholder: _ctx.$t('patient.couponPlaceholder'),
                class: "p-column-filter",
                onChange: ($event: any) => (filterCallback())
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "medicalTestInProgress",
            header: _ctx.$t('patient.medicalTestInProgress'),
            sortable: ""
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_Tag, {
                class: _normalizeClass(_ctx.getTagFromTestType(slotProps.data.medicalTestInProgress))
              }, {
                default: _withCtx(() => [
                  (slotProps.data.medicalTestInProgress)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t(`medicalTestTypes.${slotProps.data.medicalTestInProgress}`)), 1)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(" --- ")
                      ], 64))
                ]),
                _: 2
              }, 1032, ["class"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "wearables",
            header: _ctx.$t('patient.wearables'),
            sortable: ""
          }, {
            body: _withCtx((slotProps) => [
              (slotProps.data.wearables)
                ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                : (_openBlock(), _createElementBlock("img", _hoisted_6))
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "nutritionalPlan",
            header: _ctx.$t('patient.nutritionalPlan'),
            sortable: ""
          }, {
            body: _withCtx((slotProps) => [
              (slotProps.data.nutritionalPlan)
                ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                : (_openBlock(), _createElementBlock("img", _hoisted_8))
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "ownerSpecialists",
            header: _ctx.$t('patient.specialists-short')
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("p", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOwnerSpecialists(slotProps.data), (specialist) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: specialist.id,
                    class: "specialist-item-value"
                  }, _toDisplayString(specialist.name) + " " + _toDisplayString(specialist.surname), 1))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "viewerSpecialists",
            header: _ctx.$t('patient.specialists')
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("p", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getViewerSpecialists(slotProps.data), (specialist) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: specialist.id,
                    class: "specialist-item-value"
                  }, _toDisplayString(specialist.name) + " " + _toDisplayString(specialist.surname), 1))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "inactive",
            header: _ctx.$t('patient.state'),
            showClearButton: false,
            showApplyButton: false,
            showFilterMatchModes: false,
            sortable: ""
          }, {
            body: _withCtx((slotProps) => [
              (slotProps.data.inactive)
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t('patient.inactive')), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$t('patient.active')), 1))
            ]),
            filter: _withCtx(({ filterModel, filterCallback }) => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", {
                  class: _normalizeClass(["inactive-filter", { 'filter-selected': filterModel.value && (filterModel.value as boolean[]).includes(true) }])
                }, [
                  _createVNode(_component_Checkbox, {
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    inputId: "verified-filter",
                    value: true,
                    onChange: ($event: any) => (filterCallback())
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('patient.active')), 1)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["inactive-filter", { 'filter-selected': filterModel.value && (filterModel.value as boolean[]).includes(false) }])
                }, [
                  _createVNode(_component_Checkbox, {
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    inputId: "verified-filter-2",
                    value: false,
                    onChange: ($event: any) => (filterCallback())
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
                  _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('patient.inactive')), 1)
                ], 2)
              ])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "lastActivity",
            header: _ctx.$t('patient.lastActivity'),
            sortable: ""
          }, {
            body: _withCtx((slotProps) => [
              (slotProps.data.lastActivity)
                ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.getLastAgo(slotProps.data.lastActivity)), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString('-')))
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('table.actions')
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_Button, {
                class: "p-button-table",
                onClick: ($event: any) => (_ctx.onPatientDetail(slotProps.data))
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createElementVNode("i", {
                    class: "icon-show",
                    "aria-hidden": "true",
                    "aria-label": "Adios"
                  }, null, -1)
                ])),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_Button, {
                class: "p-button-table",
                onClick: ($event: any) => (_ctx.onPatientEdit(slotProps.data))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "icon-edit",
                    "aria-hidden": "true",
                    "aria-label": _ctx.$t('common.edit')
                  }, null, 8, _hoisted_18)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_Button, {
                class: "p-button-table",
                onClick: ($event: any) => (_ctx.onCreateChat(slotProps.data))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "icon-new-chat",
                    "aria-hidden": "true",
                    "aria-label": _ctx.$t('common.edit')
                  }, null, 8, _hoisted_19)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["filters", "value", "loading", "paginator", "onRowClick"]))
    : _createCommentVNode("", true)
}